var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "w": "100%"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "0",
      "pb": "20px",
      "paths": _vm.breadcrumbs
    }
  }), _c('c-box', {
    attrs: {
      "position": "relative",
      "mx": "auto",
      "mb": ['0', '16px'],
      "p": ['1rem', '30px'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "width": "100%"
    }
  }, [_c('ManagementClientDetailAlt', {
    attrs: {
      "export-url": _vm.exportUrl,
      "role": _vm.authRole
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }