<template>
  <c-flex
    flex-dir="column"
    w="100%"
  >
    <BreadcrumbPath
      px="0"
      pb="20px"
      :paths="breadcrumbs"
    />
    <c-box
      position="relative"
      mx="auto"
      :mb="['0', '16px']"
      :p="['1rem', '30px']"
      border="1px solid #f2f2f2"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      width="100%"
    >
      <ManagementClientDetailAlt
        :export-url="exportUrl"
        :role="authRole"
      />
    </c-box>
    <!-- <ManagementClientDetail
      :export-url="exportUrl"
      :client="client"
      @detail-program="
        $router.push({
          name: 'admin.clients-programs',
          params: { programId: $event },
        })
      "
    /> -->
  </c-flex>
</template>

<script>
// import ManagementClientDetail from '@/components/managements/clients/detail'
import ManagementClientDetailAlt from '@/components/managements/clients/detail-alt'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'

export default {
  name: 'AdminClientsDetail',
  components: { 
    // ManagementClientDetail, 
    ManagementClientDetailAlt, 
    BreadcrumbPath,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          label: 'Manajemen Klien',
          href: '/admin/clients',
          isCurrent: false,
        },
        {
          label: 'Detail Klien',
          isCurrent: true,
        },
      ]
    },
    client() {
      return this.$store.getters['admClients/clientById'](this.clientId)
    },
    exportUrl() {
      let baseUrl = this.$store.getters.axios.defaults.baseURL
      let token = this.$store.state.auth.token
      let params = new URLSearchParams()
      params.set('token', token)

      return `${baseUrl}/v1/admin/clients/${
        this.clientId
      }/export?${params.toString()}`
    },
  },
  // watch: {
  //   clientId: {
  //     immediate: true,
  //     handler() {
  //       this.$store.dispatch('admClients/getClientById', this.clientId)
  //     },
  //   },
  // },
}
</script>
